import React from 'react';
import { graphql } from 'gatsby';
import { ProjectsGrid, Layout } from 'components';
import { OneCol } from 'slices';
import '../pages/projects.scss';

export const ProjectCategory = ({ location, data }) => {
  const { projectCategory, allPrismicProject: projects } = data;
  const { data: pageData } = projectCategory;

  const {
    title,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    hide_footer_links: hideFooterLinks,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.fixed.src,
    schema: schema.text,
  };

  return (
    <Layout location={location} seo={seo} hideFooterLinks={hideFooterLinks} className="page">
      <section className="projects-page">
        <OneCol data={{ primary: { title } }} />
        <ProjectsGrid projects={projects?.nodes || []} />
      </section>
    </Layout>
  );
};

export default ProjectCategory;

export const pageQuery = graphql`
  query ProjectCategoryBySlug($uid: String!) {
    projectCategory: prismicProjectCategory(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        title {
          text
          html
        }
      }
    }
    allPrismicProject(filter: { data: { category: { uid: { eq: $uid } } } }) {
      nodes {
        data {
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          project_title {
            text
          }
        }
        uid
      }
    }
  }
`;
